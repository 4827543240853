.filter-table {
	border-collapse: collapse;
	.sort-column {
		width: 40px;
		font-size: 40px;
		.svg {
			@include transition-standard;
			cursor: pointer;
			width: 34px;
			&:hover {
				color: $brand-primary;
			}
			&.disabled {
				color: #CCC;
				cursor: default;
				&:hover {
					color: #CCC;
				}
			}
		}
	}
	&.sort-table {
		thead {
			pointer-events: none;
		}
	}
	tbody {
		@include transition-standard;
		cursor: pointer;
		&:nth-of-type(odd) {
			background-color: rgba(222, 222, 222, 0.3);
			@include media-dark {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}
		&:hover {
			color: $brand-light;
			background-color: $brand-primary;
			a {
				color: $brand-light;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}