.filter-table {
	margin-top: 10px;
	margin-bottom: 10px;
	.column-image {
		width: 46px;
		.lazy-background {
			height: 46px;
			border-radius: 50%;
			width: 46px;
		}
	}
	td, th {
		padding: 3px;
		vertical-align: middle;
		&.actions {
			width: 110px;
			text-align: right;
			a {
				display: inline-block;
				.svg {
					@include transition-standard;
					width: 30px;
					height: 30px;
				}
			}
		}
		&.created_at_formatted,
		&.updated_at_formatted {
			width: 200px;
		}
	}
	thead {
		a {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			color: $brand-dark;
			@include media-dark {
				color: $brand-light;
			}
			&.active,
			&:hover {
				color: $brand-primary;
			}
			.svg {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}
	}
}