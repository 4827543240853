// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400&display=swap');

// Variables
@import 'a_settings/_global';

@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
@import '~@pixelcore/ui/scss/ui.scss';

@import 'b_tools/_mixins';

@import 'c_elements/_elements';

@import 'd_objects/_forms';
@import 'd_objects/_interior';
@import 'd_objects/_login';
@import 'd_objects/_tables';

@import 'e_components/_companies';
@import 'e_components/_filter-table';
@import 'e_components/_scrollbars';
@import 'e_components/_secrets';
@import 'e_components/_tasks';
@import 'e_components/_timesheet';