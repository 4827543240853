* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

html,
body {
    background-color: $brand-light;
    font-family: 'Noto Sans', sans-serif;
    color: $brand-dark;
    font-size: 16px;
    line-height: 120%;
    width: 100%;
    height: 100%;
    @include media-dark {
        background-color: $brand-dark;
        color: $brand-light;
    }
}

h1, h2, h3, h4, h5, h6, p {
    line-height: 120%;
    margin-bottom: 20px;
    font-weight: normal;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 16px;
}

img {
    max-width: 100%;
}

small {
    font-size: 75%;
    line-height: 75%;
}

table {
    width: 100%;
    th {
        text-align: left;
    }
}

a {
    @include transition-standard;
    text-decoration: none;
    color: $brand-primary;
    &:visited {
        color: $brand-primary;
    }
    &:hover, &:active {
        color: $brand-secondary;
    }
}

.svg {
    display: inline-block;
    vertical-align: middle;
    svg {
        width: 100%;
        height: 100%;
        pointer-events: none;
        path, polyline, circle {
            stroke: currentColor;
        }
    }
}

.lazy-image {
    display: inline-block;
}
.lazy-background {
    flex-shrink: 0;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    &.round {
        width: 100%;
        padding-top: 100%;
        border-radius: 50%;
    }
    > .svg {
        position: absolute;
        top: 0;
        left: 0;
        .svg-loader {
            path {
                stroke: none;
                fill: $brand-dark;
                @include media-dark {
                    fill: $brand-light;
                }
            }
        }
    }
}

#app {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

picture img {
    width: 100%;
}

.icon-action {
    width: 36px;
    height: 36px;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    .svg {
        @include transition-standard;
        width: 100%;
        height: 100%;
    }
}