.control-group {
    position: relative;
    margin-bottom: 20px;

    label {
        @include transition-standard;
        position: absolute;
		top: 16px;
		margin: 0;
		left: 0;
        z-index: 10;
		color: $brand-dark;
        font-weight: normal;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 15px;

		&.has-prepend {
			left: 37px;
		}
        @include media-dark {
            color: $brand-light;
        }
	}
    &.filled,
    &.active {
		label {
            font-size: 10px;
			top: -2px;
			transform: translateY(0);
            color: $brand-dark;
            @include media-dark {
                color: $brand-light;
            }
		}
	}

    input,
    input[type=text],
    input[type=password],
	input[type=email],
    input[type=number],
    input[type=date],
    input[type=datetime],
	select,
	textarea {
        width: 100%;
        /*height: 38px;*/
        padding: 14px 0px 6px 0px;
        background-color: transparent;
        font-weight: normal;
        font-family: 'Noto Sans', sans-serif;
        border: none;
        z-index: 5;
        color: $brand-dark;

        &:focus {
            outline: none;
        }
        @include media-dark {
            color: $brand-light;
        }
    }

    input[type=date]::-webkit-inner-spin-button,
    input[type=date]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    textarea {
        padding: 12px 0;
        line-height: 24px;

    }

    .input {
        position: relative;
        border-bottom: none;
        border-bottom: 1px solid $brand-dark;
        @include media-dark {
            border-bottom: 1px solid $brand-light;
        }
		&.has-append {
			padding-right: 37px;
		}

		&.has-prepend {
			padding-left: 37px;
		}

        &:after {
    		content: '';
    		position: absolute;
    		top: 100%;
    		margin-top: 0;
    		height: 1px;
    		background-color: $brand-primary;
    		width: 0;
    		-webkit-transform: translateX(-50%);
    		transform: translateX(-50%);
    		left: 50%;
    		transition: all 200ms linear 0s;
    	}

        .append-icon,
        .prepend-icon {
            @include transition-standard;
    		color: $brand-dark;
            @include media-dark {
                color: $brand-light;
            }
            &:hover {
                color: $brand-primary;
            }
    	}

        > span {
			position: absolute;
			width: 32px;
			height: 32px;
			cursor: pointer;
			text-align: center;
			top: 0;
            .svg {
                width: 100%;
                height: 100%;
            }
			&.append-icon {
				right: 0;
			}

			&.prepend-icon {
				left: 0;
			}

	        i {
	            vertical-align: middle;
	        }
		}
    }

    .input-holder {
    	position: relative;

        &.has-append {
            padding-right: 32px;
        }

        &.has-prepend {
        	padding-left: 32px;
        }

        > span {
            display: inline-block;
            position: absolute;
            top: 0;
            cursor: pointer;
            width: 32px;
            text-align: center;
            height: 32px;
            .svg {
                width: 100%;
                height: 100%;
            }
        }

        > .prepend-icon {
        	left: 0;
        }

        > .append-icon {
        	right: 0;
        }
        .multiselect {
            color: $brand-dark;
            @include media-dark {
                color: $brand-light;
            }
            .multiselect__tags {
                border: none;
                background: transparent;
                padding: 15px 40px 0 0;
                .multiselect__single {
                    background: transparent;
                    margin-bottom: 0;
                    padding-left: 0;
                }
                input[type=text] {
                    padding: 0;
                    margin: 0;
                }
            }
            .multiselect__content-wrapper {
                background: #FFF;
                @include media-dark {
                    background: $brand-dark;
                }
            }
        }
    }
    .control-holder {
        position: relative;
    }
    &.active {
		label, 
        .append-icon, 
        .prepend-icon {
			color: $brand-primary;
		}
		.input:after {
			width: 100%;
		}
	}

    &.error {

		label,
        .prepend-icon,
        .append-icon {
			color: $danger;
		}

		&.active label,
        &.active .prepend-icon,
        &.active .append-icon {
			color: $brand-primary;
		}

		.input {
			border-bottom: 1px solid $danger;
		}
	}

	&.disabled {

		.input {
			border-bottom: 1px dashed $brand-dark;
            @include media-dark {
                border-bottom: 1px dashed $brand-light;
            }
		}

		label {
			color: $brand-dark;
            @include media-dark {
                color: $brand-light;
            }
		}
	}

	.error-text {
        margin-top: 3px;
        font-size: 12px;
        line-height: 100%;
		color: $danger;
	}
}

button, .btn {
    @include transition-standard;
    background-color: transparent;
    color: $brand-primary;
    font-size: 18px;
    border: 1px solid $brand-primary;
    border-radius: 30px;
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.block {
        width: 100%;
    }
    &:hover {
        background-color: $brand-primary;
        color: $brand-light;
    }
    &:disabled, &.disabled {
        opacity: 0.7;
        cursor: auto;
        pointer-events: none;
    }
    .svg {
        width: 22px;
        height: 22px;
        margin-left: 5px;
        margin-right: -4px;
        path {
            stroke-width: 1.5px;
        }
    }
    &.warning {
        border: 1px solid $warning;
        color: $warning;
        &:hover {
            color: $brand-light;
            background-color: $warning;
        }
    }
    &.danger {
        border: 1px solid $danger;
        color: $danger;
        &:hover {
            color: $brand-light;
            background-color: $danger;
        }
    }
}

.check-holder {
    display: inline-block;

    .checkbox {
        cursor: pointer;
        padding-left: 23px;

        .outer {
            @include transition-standard;
            position: relative;
            top: -1px;
            border: 1px solid $brand-dark;
            width: 15px;
            height: 15px;
            display: inline-block;
            vertical-align: middle;
            border-radius: $border-radius;
            margin-right: 3px;
            margin-left: -23px;
            @include media-dark {
                border-color: $brand-light;
            }
            &.checked:after {
                content: '';
                display: inline-block;
                background-color: $brand-dark;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 1px;
                left: 1px;
                -webkit-transition: all 200ms linear 0s;
                transition: all 200ms linear 0s;
                border-radius: $border-radius;
                @include media-dark {
                    background-color: $brand-light;
                }
            }
        }

        &:hover {

            .outer {
                border-color: $brand-primary;

                &.checked:after {
                    background-color: $brand-primary;
                }
            }
        }
    }

    &.disabled {

        .checkbox {
            cursor: default;
            .outer {
                border-style: dashed;
            }
            &:hover {

                .outer {
                    border-color: $brand-dark;
                    @include media-dark {
                        border-color: $brand-light;
                    }
                    &.checked:after {
                        background-color: $brand-dark;
                        @include media-dark {
                            background-color: $brand-light;
                        }
                    }
                }
            }
        }
    }
}

.image-input,
.file-input {
    margin-bottom: 10px;
    .actions {
        display: flex;
        button {
            width: 100%;
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .error-text {
        margin-top: 3px;
        font-size: 12px;
        line-height: 100%;
        color: $danger;
    }
}

form {
    .actions {
        display: flex;
        justify-content: flex-end;
        button, .btn {
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}