#app {
	&.logged-in {
		padding-left: 0;
		@include media-breakpoint-up(md) {
			padding-left: 280px;
		}
		
	}
	.mobile-back {
		@include transition-standard;
		z-index: 2000;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: $brand-light;
		@include media-dark {
			background-color: $brand-dark;
		}
		.menu-toggle {
			position: absolute;
			top: 25px;
			right: 20px;
			padding: 5px;
        	display: flex;
        	align-items: center;
        	justify-content: center;
        	.svg {
        		width: 40px;
        		height: 40px;
        	}
		}
	}
	.top-bar {
		@include background;
		@include color;
		padding: 20px;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		@include media-breakpoint-up(md) {
			padding-left: 300px;
		}
		.inner {
			min-height: 60px;
			display: flex;
			padding-left: 20px;
			justify-content: flex-end;
			align-items: center;
			border-radius: $border-radius;
			background-color: $body-panel-light;
			width: 100%;
			@include media-dark {
				background-color: $body-panel-dark;
			}
			h2 {
	        	margin-bottom: 0;
	        	flex-grow: 1;
	        	overflow: hidden;
	        	white-space: nowrap;
	        }
	        .user-menu-toggle {
	        	position: relative;
	        	cursor: pointer;
	        	padding: 10px;
	        	border-top-right-radius: $border-radius;
	        	border-bottom-right-radius: $border-radius;
	        	@include transition-standard;
	        	&:hover {
	        		background-color: $brand-primary;
	        		color: $brand-light;
	        	}
	        	.details {
	        		display: flex;
	        		align-items: center;
	        		.lazy-background {
	        			flex-shrink: 0;
	        			height: 40px;
	        			width: 40px;
	        			border-radius: 50%;
	        			margin-right: 10px;
	        		}
	        	}
	        	.dropdown {
	        		width: 100%;
	        		position: absolute;
	        		top: 100%;
	        		left: 0;
	        		border-radius: $border-radius;
	        		padding: 10px;
	        		background-color: $body-panel-light;
	        		@include media-dark {
	        			background-color: $body-panel-dark;
	        		}
	        		ul {
	        			list-style: none;
	        			padding: 0;
	        			margin: 0;
	        			li {
	        				list-style: none;
	        				padding: 0;
	        				margin: 0;
	        			}
	        		}
	        	}
	        }
	        .menu-toggle {
	        	padding: 5px;
	        	display: flex;
	        	align-items: center;
	        	justify-content: center;
	        	.svg {
	        		width: 40px;
	        		height: 40px;
	        	}
	        }
		}
	}
	.side-bar {
		@include transition-standard;
		width: 260px;
	    position: fixed;
	    z-index: 2001;
	    left: 20px;
	    top: 20px;
	    height: 100%;
	    border-radius: $border-radius;
	    padding: 10px 0;
    	background-color: $body-panel-light;
    	overflow-y: hidden;
        @media(prefers-color-scheme: dark) {
            background-color: $body-panel-dark;
        }
        .content-holder {
        	padding-left: 10px;
        	padding-right: 10px;
        }
        .user-info {
        	margin-top: 10px;
		    margin-bottom: 10px;
		    padding-top: 10px;
		    padding-bottom: 10px;
		    border-bottom: 1px solid $brand-dark;
		    border-top: 1px solid $brand-dark;
		    display: flex;
		    align-items: center;
		    @media(prefers-color-scheme: dark) {
		    	border-top: 1px solid $brand-light;
		    	border-bottom: 1px solid $brand-light;
		    }
		    .lazy-background {
		    	flex-shrink: 0;
		    	width: 60px;
		    	height: 60px;
		    	border-radius: 50%;
		    	margin-right: 10px;
		    }
        }
        ul {
        	list-style: none;
        	padding: 0;
        	margin: 0;
        	li {
        		list-style: none;
        		margin: 0;
        		padding: 0;
        		margin-bottom: 5px;
        		.submenu {
        			padding-left: 20px;
        		}
        		a {
        			transition: padding 200ms ease-out, color 200ms ease-out, border-color 200ms ease-out;
        			align-items: center;
        			display: flex;
        			padding: 0;
        			padding-right: 10px;
        			margin-bottom: 10px;
        			margin-left: 10px;
        			border-radius: 0;
        			justify-content: space-between;
        			color: $brand-dark;
        			@include media-dark {
        				color: $brand-light;
        			}
        			&:hover {
        				padding-left: 10px;
        				color: $brand-primary;
        				border-left: 2px solid $brand-primary;
        			}
        			.svg {
        				display: flex;
        			}
        		}
        		&.dashboard-link {
        			a.router-link-exact-active {
        				padding-left: 10px;
	        			color: $brand-primary;
	        			border-left: 2px solid $brand-primary;
        			}
        		}
        		&:not(.dashboard-link) {
        			a {
        				&.router-link-active {
	        				padding-left: 10px;
	        				color: $brand-primary;
	        				border-left: 2px solid $brand-primary;
	        			}
        			}
        		}
        	}
        }
        .user-menu {
        	margin-bottom: 10px;
        	border-bottom: 1px solid $brand-dark;
        	@include border;
        	.svg {
        		order: 1;
        	}
        	li {
        		margin-left: -10px;
        		margin-right: -10px;
        	}
        }
	}
	.main-content {
		padding-left: 20px;
		padding-top: 100px;
		padding-right: 20px;
		margin-bottom: 20px;
		@include media-breakpoint-up-custom(980px) {
			padding-left: 20px;
		}
	}
	.main-scroll.ps {
		padding-left: 0;
		padding-right: 0;
		.ps__rail-y {
			z-index: 1001;
		}
	}
	.global-timer {
		position: fixed;
	    bottom: 20px;
	    right: 20px;
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: flex-end;
	    .info {
	    	width: 100%;
	    	gap: 10px;
	    	display: flex;
	    	align-items: center;
	    	padding: 10px;
	    	border-radius: $border-radius;
	    	@include panel-background;
	    	margin-bottom: 10px;
	    	.lazy-background {
	    		width: 60px;
	    		height: 60px;
	    		border-radius: 50%;
	    	}
	    	.heading {
	    		font-weight: bold;
	    		margin-bottom: 5px;
	    	}
	    	.subheading {
	    		margin-bottom: 5px;
	    	}
	    	.content {
	    		flex-grow: 1;
	    		text-align: right;
	    	}
	    }
	}
}