#app .logged-out {
    display: flex;
    padding: 20px;
    background: linear-gradient(-45deg, $brand-primary, $brand-light);
    background-size: 200% 200%;
    height: 100%;
    @media(prefers-color-scheme: dark) {
        background: linear-gradient(-45deg, $brand-primary, $brand-dark);
    }
    .left-container {
        width: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(sm) {
            width: 40%;
        }
        @include media-breakpoint-up(md) {
            width: 60%;
        }
    }
    .login {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        width: 100%;
        padding: 20px 0;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        @include media-dark {
            background-color: rgba(0, 0, 0, 0.8);
        }
        @include media-breakpoint-up(sm) {
            width: 60%;
        }
        @include media-breakpoint-up(md) {
            width: 40%;
        }
        progress {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        .ps {
            padding: 0 20px;
            display: flex;
            align-items: center;
        }
        .login-content {
            width: 100%;
            max-width: 480px;
            .logo {
                display: block;
                margin: 0 auto;
                margin-bottom: 20px;
                img {
                    display: block;
                    margin: 0 auto;
                }
            }
            p {
                text-align: center;
            }
            .actions {
                /*margin-bottom: 20px;*/
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .profile {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                .lazy-background {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    margin-right: 20px;
                }
                h3 {
                    margin-bottom: 0;
                }
            }
        }
    }
}