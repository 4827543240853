.companies {
	display: flex;
    flex-wrap: wrap;
    gap: 20px;
    > .card,
    > .column {
    	width: 100%;
    	@include media-breakpoint-up-custom(900px) {
    		width: calc(50% - 10px);
    	}
    	
    }
	.details {
		.card-image,
		.input-lazy-background {
			width: 25%;
			padding-top: 25%;
			border-radius: 50%;
		}
		.info {
			width: 75%;
			h4 {
				font-weight: bold;
				border-bottom: 1px solid;
				@include border;
				margin-bottom: 10px;
				padding-bottom: 10px;
			}
		}
	}
}

.company-link {
	@include color;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	.lazy-background {
		width: 60px;
		padding-top: 60px;
		margin-right: 10px;
	}
	&:visited {
		@include color;
	}
	&:hover {
		@include color;
	}
	.name {
		flex-grow: 1;
	}
}