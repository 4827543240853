.task-view {
	.task-item {
		display: flex;
		margin-bottom: 5px;
		flex-wrap: wrap;
		> div {
			display: flex;
			width: 100%;
		}
		.header {
			.task-actions,
			.mark {
				flex-shrink: 0;
			}
			.name {
		    	width: 100%;
		    	min-height: 36px;
			    display: flex;
			    flex-grow: 1;
			    align-items: center;
			    padding-left: 5px;
			    padding-right: 5px;
		    }
		}
		.content {
			padding-left: 41px;
			width: 100%;
		    font-size: 80%;
		    font-style: italic;
		}
	}
}