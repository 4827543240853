.timesheet {
	.controls {
		display: flex;
		align-items: center;
		justify-content: stretch;
		margin-bottom: 10px;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-grow: 1;
			padding: 10px;
			border-bottom: 2px solid transparent;
			@include color;
			&:hover {
				@include color;
			}
			&.active,
			&:hover {
				border-bottom: 2px solid $brand-primary;
			}
			&.today {
				color: $brand-primary;
			}
			&.next, 
			&.back {
				flex-grow: 0;
				&:hover {
					color: $brand-primary;
					border-bottom: 2px solid transparent;
				}
			}
		}
	}
	.task-select {
		.company {
			padding: 5px 5px 5px 0;
			> .name {
				font-weight: bold;
				font-size: 20px;
			}
			.project {
				padding: 5px 5px 5px 15px;
				> .name {
					font-weight: bold;
					font-size: 18px;
				}
				.task {
					padding: 5px 5px 5px 15px;
					> .name {
						font-weight: bold;
						font-size: 16px;
					}
					.item {
						padding: 5px 5px 5px 15px;
						@include transition-standard;
						cursor: pointer;
						&:hover,
						&.active {
							color: $brand-light;
							background-color: $brand-primary;
						}
						> .name {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}