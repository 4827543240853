.secrets {
	.field-header {
		padding-bottom: 5px;
		margin-bottom: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.secret {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
		.control-group {
			margin-right: 10px;
			margin-bottom: 0;
			width: 100%;
		}
		.type {
			min-width: 200px;
			width: 200px;
			flex-shrink: 0;
		}
		a {
			flex-shrink: 0;
		}
	}
}