#app {
	.ps {
		padding: 0 10px;
		.ps__thumb-y {
			background-color: $brand-primary;
		}
		.ps__rail-x:hover, 
		.ps__rail-y:hover,
		.ps__rail-x:focus,
		.ps__rail-y:focus,
		.ps__rail-x.ps--clicking,
		.ps__rail-y.ps--clicking {
			background-color: rgba(255, 255, 255, 0.7);
			opacity: 1;
			border-radius: 10px;
			@include media-dark {
				background-color: rgba(0, 0, 0, 0.7);
			}
		}
	}
}